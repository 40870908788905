//Needed props: Right Side queryType and seriesName, left side queryType and seriesName.
// Will have to create context connected stores for getting api data + handling view context integrations.
//Projections: {<seriesName>: [arrayOfProjections] }
// RHS: {char

import {DashboardQueryTypes} from "../../../../api/data/DashboardQueryTypes";
import {useContext, useEffect, useState} from "react";
import useElementMeasurements from "../../commonHooks/ElementMeasurementsHook";
import {ProjectionsDataStore} from "./ProjectionsDataProvider";
import {ViewContextStore} from "../views/ViewContext";
import {projectionKeyGenerator} from "./defaultProjectionsUtility";
import QuarterCharts from "../../../line_chart/QuarterCharts";
import Measure from "react-measure";
import {DashboardApiContextStore} from "../DashboardReportContextProvider";
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import {Box, Typography} from "@mui/material";


const convertChartLayoutToChartArray = (chartLayout) => {
    const chartsArray = [null, null, null, null];

    if (chartLayout.rhs != null) {
        chartsArray[1] = {
            seriesName: chartLayout.rhs?.seriesName,
            algorithm: chartLayout.rhs?.projections[0]?.algorithm,
            queryType: chartLayout.rhs?.projections[0]?.queryType,
        };
        chartsArray[3] = {
            seriesName: chartLayout.rhs?.seriesName,
            algorithm: chartLayout.rhs?.projections[1]?.algorithm,
            queryType: chartLayout.rhs?.projections[1]?.queryType,
        };
    }

    if (chartLayout.lhs != null) {
        chartsArray[0] = {
            seriesName: chartLayout.lhs?.seriesName,
            algorithm: chartLayout.lhs?.projections[0]?.algorithm,
            queryType: chartLayout.lhs?.projections[0]?.queryType,
        };
        chartsArray[2] = {
            seriesName: chartLayout.lhs?.seriesName,
            algorithm: chartLayout.lhs?.projections[1]?.algorithm,
            queryType: chartLayout.lhs?.projections[1]?.queryType,
        };
    }
    return chartsArray;
}

const getChartHeadersForChartLayout = (chartLayout) => {
    let headerString = '';
    if (chartLayout.lhs != null) {
        headerString = headerString + chartLayout.lhs?.seriesName;
    }
    if (chartLayout.rhs != null) {
        if (headerString !== '') headerString = headerString + ' / ';
        headerString = headerString + chartLayout.rhs?.seriesName;
    }
    return headerString;
}

const ESTIMATED_INITIAL_HEIGHT = 500;
const ESTIMATED_INITIAL_WIDTH = 500;

const ContextConnectedProjectionsChart = ({chartDetails, chartHeight, chartWidth}) => {
    if (chartDetails == null) return <></>;
    const {seriesName, algorithm, queryType} = chartDetails;
    const projectionKey = projectionKeyGenerator(seriesName, queryType, algorithm);
    const {dataState} = useContext(ProjectionsDataStore);
    const chartDataState = dataState[projectionKey];
    const completeViewData = useContext(ViewContextStore);
    const idioData = useContext(DashboardApiContextStore)?.dataState[DashboardQueryTypes.IDIO]?.chartData?.data;
    const viewData = completeViewData?.viewState[projectionKey];
    //Todo: figure out arrow based navigation for the grid:
    const chartToggleConfig = {
        viewData,
        modifyActiveSeries: (seriesName) => completeViewData?.modifyActiveSeries(projectionKey, seriesName),
        updateChartRange: (extremities) => completeViewData?.updateChartRange(projectionKey, extremities),
        setCombineAxes: (combineAxes) => completeViewData?.setCombineAxes(projectionKey, combineAxes),
        setDynamicLag: (dynLag) => completeViewData?.setDynamicLag(projectionKey, dynLag),
        compactViewModalState: false,
        compactViewToggleModal: () => {
        },
        dropLastPoint: completeViewData.dropLastPoint,
    }
    const toggleOptionsConfig = {
        isDailyStackAlignment: completeViewData.isDailyStackAlignment,
        setDailyStackAlignment: completeViewData.setDailyStackAlignment,
        showWeeklySeries: completeViewData.showWeeklySeries,
        setShowWeeklySeries: completeViewData.setShowWeeklySeries,
    }

    return (<QuarterCharts
        chartData={chartDataState?.chartData}
        loading={chartDataState?.loading}
        isError={chartDataState?.isError}
        chartToggleConfig={chartToggleConfig}
        toggleOptionsConfig={toggleOptionsConfig}
        idioData={idioData}
        chartWidth={chartWidth}
        chartHeightOverride={chartHeight}
        activeTicker={completeViewData.tick.name}
        activeBrand={completeViewData.brand.id}
        activeBrandName={completeViewData.brand.name}
        queryType={queryType}
        showMiniView={false}
        isMiniMode={false}
        yearsToShow={5}
    />);

}

const getRowColumnStyleForIndex = index => ({
    gridRow: index < 2 ? '1' : '2',
    gridColumn: index % 2 + 1,
    height: '100%'
})

export const ProjectionsChartGrid = ({chartLayout, removeProjections}) => {

    const [chartsArray, setChartsArray] = useState(convertChartLayoutToChartArray(chartLayout));

    const [chartHeaderString, setChartHeaderString] = useState(getChartHeadersForChartLayout(chartLayout));

    useEffect(() => {
        setChartsArray(convertChartLayoutToChartArray(chartLayout));
        setChartHeaderString(getChartHeadersForChartLayout(chartLayout));
    }, [chartLayout])

    const [dimensions, handleResize]
        = useElementMeasurements(ESTIMATED_INITIAL_HEIGHT, ESTIMATED_INITIAL_WIDTH);

    const removeProj = () => removeProjections(chartLayout.lhs?.seriesName, chartLayout.rhs?.seriesName)

    return (
        <>
            <Alert
                severity="info"
                icon={false}
                sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
            >
                <Box sx={{ flexGrow: 1, textAlign: 'center', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <Typography variant="body1">{chartHeaderString}</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    color="inherit"
                    size="small"
                    onClick={removeProj}
                    sx={{
                        border: '1px solid',
                        borderColor: 'currentColor',
                    }}
                >
                    Remove Projection
                </Button>
            </Alert>
            <div style={{
                display: 'grid',
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "1fr 1fr",
                gap: '0px',
                width: '100vw',
                height: '100vh', marginBottom: '10px', marginTop: '10px'
            }}>
                {chartsArray.map((chart, index) => (
                    <Measure
                        bounds
                        onResize={handleResize}>
                        {({measureRef}) => (
                            <div ref={measureRef}
                                 style={getRowColumnStyleForIndex(index)}
                                 key={projectionKeyGenerator(chart?.seriesName, chart?.queryType, chart?.algorithm) + '_QTR_CHART'}>
                                <ContextConnectedProjectionsChart chartDetails={chart}
                                                                  chartHeight={dimensions.height}
                                                                  chartWidth={dimensions.width}
                                />
                            </div>
                        )}
                    </Measure>
                ))}
            </div>
        </>
    )
}