import {CAlert} from "@coreui/react";
import {Switch} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {getSpreadData} from "../../../api/data/PortfolioDataProvider";
import {debounce} from "../../../utils/StudioChartUtils";
import {getCommonEarningsEvents} from "../../../api/data/DataProvider";
import dayjs from "dayjs";

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function getColor(seriesName) {
    seriesName = seriesName.toLowerCase();
    if (seriesName.startsWith('equal') || seriesName.startsWith('net')) {
        return '#ff00e4';
    } else if (seriesName.startsWith('contribution') || seriesName.startsWith('gross')) {
        return '#636efa';
    } else if (seriesName.startsWith('long')) {
        if(seriesName.includes('equal')) {
            return '#03f84f';
        } else {
            return '#008a27';
        }
    } else if (seriesName.startsWith('short')) {
        if(seriesName.includes('equal')) {
            return '#fa3cb9';
        } else {
            return '#f85134';
        }
    } else if (seriesName.startsWith('nav')) {
        return '#A73380';
    } else {
        return getRandomColor();
    }
}

const indicesToInclude = ['tlt', 'spy', 'qqq', 'xrt', 'igv', 'xly', 'iwm'];

const spreadSeriesToShow = [
    'Contribution Weighted Spread',
    'Contribution Weighted Idio Spread',
    'Long Stock Idio (Cont Weighted)',
    'Short Stock Idio (Cont Weighted)',
];

const spreadSeriesOrder = [
    ...[
        'Contribution Weighted Spread',
        'Equal Weighted Spread',
        'Contribution Weighted Idio Spread',
        'Equal Weighted Idio Spread',
        'Long Stock (Cont Weighted)',
        'Long Stock (Equal Weighted)',
        'Long Stock Idio (Cont Weighted)',
        'Long Stock Idio (Equal Weighted)',
        'Short Stock (Cont Weighted)',
        'Short Stock (Equal Weighted)',
        'Short Stock Idio (Cont Weighted)',
        'Short Stock Idio (Equal Weighted)',
        'Hedges (Cont Weighted)',
        'Hedges (Equal Weighted)',
        'Total Short (Cont Weighted)',
        'Total Short (Equal Weighted)',
    ].map(s => s.toLowerCase()),
    ...indicesToInclude.map(s => s.toLowerCase()),
]

const grossSeriesOrder = [
    'Gross Value',
    'Net Value',
    'NAV',
];

function SpreadChart(props) {
    const [originalData, setOriginalData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [grossChartData, setGrossChartData] = useState(null);
    const [loading, setLoading] = useState(chartData == null);
    const [isError, setIsError] = useState(false);
    const [rebaseToZero, setRebaseToZero] = useState(true);
    const [grossInPercent, setGrossInPercent] = useState(true);
    const [earningEvents, setEarningEvents] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();

        setIsError(false)
        setLoading(true)
        getSpreadData(props.accountGroup?.accountIds, indicesToInclude, abortController)
            .then((res) => {
                setOriginalData(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    setIsError(true);
                }
            })


        return () => abortController.abort();
    }, []);

    useEffect(() => {
        if (originalData) {
            setChartData((prev) => {
                return {
                    series: [...calculateSeriesData(originalData)],
                    title: {
                        text: 'Performance & Spread'
                    },
                    tooltip: {
                        shared: true,
                        split: true,
                        inactiveOtherSeries: false,
                        xDateFormat: "%A, %e %B, %Y",
                        pointFormatter: function () {
                            return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                Highcharts.numberFormat(this.y * 100, 2) + '%</b><br/>';
                        },
                    },
                    chart: {
                        zoomType: "x",
                        zooming: {
                            mouseWheel: {
                                enabled: false
                            }
                        },
                        height: 600,
                        plotBackgroundColor: '#E5ECF6',
                        panning: {
                            enabled: true,
                            type: 'x'
                        },
                        panKey: 'shift',
                    },
                    legend: {
                        enabled: true,
                        align: "right",
                        verticalAlign: "middle",
                        layout: "vertical",
                        width: 200,
                        itemWidth: 200,
                        itemStyle: {
                            color: "#000000",
                            fontSize: '12px',
                            textOverflow: 'ellipsis'
                        },
                        itemHiddenStyle: {
                            textDecoration: 'none'  // Disable strikethrough effect
                        }
                    },
                    navigation: {
                        buttonOptions: {
                            align: "right",
                            verticalAlign: "top",
                            y: 10
                        }
                    },
                    plotOptions: {
                        marker: {
                            enabled: false
                        }
                    },
                    xAxis: {
                        type: "datetime",
                        labels: {
                            enabled: true
                        },
                        dateTimeLabelFormats: {
                            day: "%Y-%m-%d"
                        },
                        gridLineWidth: 1,
                        gridLineColor: "#fff",
                        events: {
                            afterSetExtremes: debounce((e) => {
                                // Recalculate series here based on available range
                                let chart = e?.target?.chart;
                                if (chart && originalData) {
                                    let startTimestamp = dayjs(e.min).add(1, 'day').startOf('day').utc(true).valueOf();

                                    let spreadData = rebaseToZero ? calculateSeriesData(originalData, startTimestamp)
                                        : calculateSeriesData(originalData);
                                    if (spreadData) {
                                        for (let series of spreadData) {
                                            let seriesName = series.name;
                                            let seriesData = series.data;
                                            let seriesObject = chart?.series.find(s => s.name === seriesName);
                                            if (seriesObject) {
                                                seriesObject.update({data: seriesData}, true);
                                            }
                                        }
                                    }
                                }
                            }, 100)
                        },
                        plotBands: calculatePlotBands(),
                    },
                    yAxis: [
                        {
                            title: {
                                text: "Return Percent %"
                            },
                            visible: true,
                            opposite: false,
                            gridLineWidth: 1,
                            gridLineColor: "#fff",
                            labels: {
                                formatter: function () {
                                    return (this.value * 100) + '%';
                                }
                            }
                        }
                    ]
                }
            });
            setGrossChartData((prev) => {
                return {
                    series: !grossInPercent ? [...getGrossChartSeriesData(originalData)]
                        : [...getGrossChartSeriesData(originalData, Object.values(originalData)[0][0].dataDateMillis)],
                    title: {
                        text: 'Gross Value'
                    },
                    tooltip: {
                        shared: true,
                        split: true,
                        inactiveOtherSeries: false,
                        xDateFormat: "%A, %e %B, %Y",
                        pointFormatter:
                            grossInPercent ?
                                function () {
                                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                        Highcharts.numberFormat(this.y * 100, 2) + '%</b><br/>';
                                }
                                :
                                function () {
                                    let formattedNumber = Highcharts.numberFormat(Math.abs(this.y), 0, '.', ',');
                                    let signCheck = this.y < 0 ? '(' : '';
                                    let signCheckClose = this.y < 0 ? ')' : '';

                                    return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                        signCheck + '$' + formattedNumber + signCheckClose + '</b><br/>';
                                }
                    },
                    chart: {
                        zoomType: "x",
                        zooming: {
                            mouseWheel: {
                                enabled: false
                            }
                        },
                        height: 600,
                        plotBackgroundColor: '#E5ECF6',
                        panning: {
                            enabled: true,
                            type: 'x'
                        },
                        panKey: 'shift'
                    },
                    legend: {
                        enabled: true,
                        align: "right",
                        verticalAlign: "middle",
                        layout: "vertical",
                        width: 200,
                        itemWidth: 200,
                        itemStyle: {
                            color: "#000000",
                            fontSize: '12px',
                            textOverflow: 'ellipsis'
                        },
                        itemHiddenStyle: {
                            textDecoration: 'none'  // Disable strikethrough effect
                        }
                    },
                    navigation: {
                        buttonOptions: {
                            align: "right",
                            verticalAlign: "top",
                            y: 10
                        }
                    },
                    plotOptions: {
                        marker: {
                            enabled: false
                        }
                    },
                    xAxis: {
                        type: "datetime",
                        labels: {
                            enabled: true
                        },
                        dateTimeLabelFormats: {
                            day: "%Y-%m-%d"
                        },
                        gridLineWidth: 1,
                        gridLineColor: "#fff",
                        events: {
                            afterSetExtremes: debounce((e) => {
                                // Recalculate series here based on available range
                                let chart = e?.target?.chart;
                                if (chart && originalData) {
                                    let startTimestamp = dayjs(e.min).add(1, 'day').startOf('day').utc(true).valueOf();
                                    console.log(`Min timestamp vs adjusted timestamp: ${dayjs(e.min).format()} || ${dayjs(startTimestamp).format()}`);

                                    let grossData = grossInPercent ? getGrossChartSeriesData(originalData, startTimestamp)
                                        : getGrossChartSeriesData(originalData);
                                    if (grossData) {
                                        for (let series of grossData) {
                                            let seriesName = series.name;
                                            let seriesData = series.data;
                                            let seriesObject = chart?.series.find(s => s.name === seriesName);
                                            if (seriesObject) {
                                                seriesObject.update({data: seriesData}, true);
                                            }
                                        }
                                    }
                                }
                            }, 100)
                        },
                        plotBands: calculatePlotBands(),
                    },
                    yAxis: [
                        grossInPercent ?
                            {
                                title: {
                                    text: "Return Percent %"
                                },
                                visible: true,
                                opposite: false,
                                gridLineWidth: 1,
                                gridLineColor: "#fff",
                                labels: {
                                    formatter: function () {
                                        return (this.value * 100) + '%';
                                    }
                                }
                            }
                            :
                            {
                                title: {
                                    text: "USD"
                                },
                                visible: true,
                                opposite: false,
                                gridLineWidth: 1,
                                gridLineColor: "#fff",
                                labels: {
                                    formatter: function () {
                                        let value = this.value;
                                        let absValue = Math.abs(value);
                                        let formattedNumber = Highcharts.numberFormat(absValue > 1000000 ?
                                            absValue / 1000000 :
                                            absValue / 1000, 0);
                                        let prefix = absValue > 1000000 ? 'M' : 'K';
                                        let sign = value < 0 ? '-' : '';
                                        return sign === '-'
                                            ? '(' + '$' + formattedNumber + prefix + ')'
                                            : '$' + formattedNumber + prefix;
                                    }
                                }
                            }
                    ]
                }
            });
        }

    }, [originalData, rebaseToZero, earningEvents, grossInPercent]);

    useEffect(() => {
        getCommonEarningsEvents()
            .then((res) => {
                let data = res.data;
                data = data.filter(e => e?.eventType === 'EARNINGS_SEASON')
                setEarningEvents(data);
            })
            .catch((err) => {
                console.error("Failed to get earning events!", err);
            })
    }, []);

    const calculateSeriesData = (rawData, startTimestamp) => {
        startTimestamp = startTimestamp || -1;
        let seriesArr = [];

        for (const series of Object.values(rawData)) {
            if (series.length !== 0 && spreadSeriesOrder.includes(series[0].source.toLowerCase())) {
                series.sort((a, b) => {
                    return a.dataDateMillis - b.dataDateMillis;
                });

                let prevValue = 0.0, currValue = 0.0;
                let newSeriesData = [];
                for (let point of series) {
                    if (point?.dataDateMillis <= startTimestamp) {
                        newSeriesData.push([point?.dataDateMillis, 0.0]);
                    } else {
                        currValue = (1 + prevValue) * point?.value + prevValue;
                        newSeriesData.push([point?.dataDateMillis, currValue]);
                        prevValue = currValue;
                    }
                }

                let newSeries = {
                    data: newSeriesData,
                    name: series[0].source,
                    visible: spreadSeriesToShow.includes(series[0].source),
                    showInLegend: true,
                    color: getColor(series[0].source),
                    lineWidth: 2,
                    dashStyle: series[0].source.includes('Idio') ? 'dash' : 'solid',
                    yAxis: 0
                };

                seriesArr.push(newSeries);
            }
        }

        seriesArr.sort((a, b) => spreadSeriesOrder.indexOf(a.name.toLowerCase()) - spreadSeriesOrder.indexOf(b.name.toLowerCase()));
        return seriesArr;
    }

    const getGrossChartSeriesData = (rawData, startTimestamp) => {
        startTimestamp = startTimestamp || -1;
        let seriesArr = [];

        for (const series of Object.values(rawData)) {
            if (series.length !== 0 && grossSeriesOrder.includes(series[0].source)) {
                series.sort((a, b) => {
                    return a.dataDateMillis - b.dataDateMillis;
                });

                let startValue = null;
                let newSeriesData = [];
                for (let point of series) {
                    // This indicates the dollar series without any adjustments. Else block is the percentage series
                    if (startTimestamp === -1) {
                        let newPoint = [point?.dataDateMillis, point?.value];
                        newSeriesData.push(newPoint);
                    } else {
                        if (point?.dataDateMillis <= startTimestamp) {
                            newSeriesData.push([point?.dataDateMillis, 0.0]);
                            startValue = point?.value;
                        } else {
                            newSeriesData.push([point?.dataDateMillis, (point?.value - startValue) / startValue]);
                        }
                    }
                }

                let newSeries = {
                    data: newSeriesData,
                    name: series[0].source,
                    visible: series[0].source.toLowerCase().includes('gross'),
                    showInLegend: true,
                    color: getColor(series[0].source),
                    lineWidth: 2,
                    dashStyle: 'solid',
                    yAxis: 0
                };

                seriesArr.push(newSeries);
            }
        }

        seriesArr.sort((a, b) => grossSeriesOrder.indexOf(a.name) - grossSeriesOrder.indexOf(b.name));
        return seriesArr;
    }

    const onCalcMethodChange = (checked) => {
        setRebaseToZero(checked);
    };

    const onGrossCalcMethodChange = (checked) => {
        setGrossInPercent(checked);
    };

    const calculatePlotBands = () => {
        let plotBands = [];

        for (const event of earningEvents) {
            let startTime = dayjs(event?.startDate).valueOf();
            let endTime = dayjs(event?.endDate).valueOf();
            let eventName = event?.eventName;
            plotBands.push({
                from: startTime,
                to: endTime,
                color: 'rgba(144, 238, 144, 0.2)',
                label: {
                    // text: eventName,
                    verticalAlign: 'bottom',
                    y: -2,
                }
            });
        }
        return plotBands;
    }

    return (
        <>
            <a id='spread-chart' href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load chart.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{height: "400px", width: "90%"}}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available
                    </CAlert>
                </div>}
                {chartData != null && !isError && chartData.series.length !== 0 &&
                    <center>
                        <Switch onChange={onCalcMethodChange} checkedChildren='Recenter to 0'
                                unCheckedChildren='Static calculation' defaultChecked/>
                        <div style={{marginTop: 10}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={chartData}
                            />
                        </div>
                    </center>}
                {grossChartData != null && !isError && grossChartData.series.length !== 0 &&
                    <center>
                        <Switch onChange={onGrossCalcMethodChange} checkedChildren='Percent'
                                unCheckedChildren='Dollars' defaultChecked/>
                        <div style={{marginTop: 10}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={grossChartData}
                            />
                        </div>
                    </center>}
            </LoadingOverlay>

        </>
    )
}

export default SpreadChart;