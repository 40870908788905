import {cloneElement, createContext, memo, useContext, useEffect, useState} from "react";

import _ from "lodash";
import {DashboardApiContextStore} from "../DashboardReportContextProvider";
import {APP, CC, GT, IDIO, IDIO_SI, INDICES, PLACER, SEGMENT, SI, SW, YIPIT} from "../../components/ShortcutsDropDown";
import {
    COMPACT_VIEW_QUERY_LIST,
    DailyStackAlignmentDependentCharts,
    DashboardQueryTypes,
    listOfListOfDashboardQueriesWithPriorities
} from "../../../../api/data/DashboardQueryTypes";
import {
    appSeriesPredicate,
    ccSeriesPredicate,
    combinePredicates,
    combinePredicatesOr,
    gtSeriesPredicate,
    idioPredicate,
    idioSiPredicate,
    indexesPredicate,
    inversePredicate,
    kpiPredicate,
    oracleIndexPredicate,
    placerPredicate,
    segmentSeriesPredicate,
    similarWebPredicate,
    siPredicate,
    weekliesButtonPredicate,
    weeklyPredicate,
    yipitPredicate
} from "./ViewContextPredicatesCollection";

/*
* chartsSetup: {
*   WEEKLY_YOY: {
*       extremities:{
*       x.min: null,
*       x.max: null
* }
*
*       seriesConfig: {
*           SeriesName: {
*           visible:
*           yAxisVisibile:
* color:
*           }
*       }
* },
* toggleOptions: {
*   isDailyAxisCombined,
*   showWeeklySeries,
* }
*
*
*modals3x3: {
            open: false,
            index: null
        }
* }
* */
//functionalTags, vendorTags

export const MODES = {
    STICKY: 'STICKY',
    DEFAULT: 'DEFAULT'
};

const predicateToAllTransformer = (predicateDescriptor) => predicateDescriptor + '_ALL';

const BUTTON_TO_PREDICATE_MAP = {
    [CC]: ccSeriesPredicate,
    [GT]: gtSeriesPredicate,
    [SW]: similarWebPredicate,
    [APP]: appSeriesPredicate,
    [SEGMENT]: segmentSeriesPredicate,
    [PLACER]: placerPredicate,
    [YIPIT]: yipitPredicate,
    [INDICES]: indexesPredicate,
}

const createToggleFunction = (stateSetterFunction, initalState = false) => {
    return () => {
        stateSetterFunction(prev => {
            if(prev == null) return initalState;
            return !prev;
        })
    }
}

// Create empty objects against each query type:
const viewContextStoreBuilder = (combineAxes) => {
    const flatListOfDashboardQueries = listOfListOfDashboardQueriesWithPriorities.flat();
    const contextsStore = {};
    const combineAxesValue = combineAxes == null ? false : combineAxes;
    flatListOfDashboardQueries.forEach((query) => {
        contextsStore[query] = {
            seriesConfig: {
                // Keeping it empty for now
            },
            extremities: {
                'xMin': null,
                'xMax': null
            },
            dynamicLag: null,
            combineAxes: combineAxesValue,
            seriesMetadata: null, // For series metadata sent from the service.
        };
    });
    return contextsStore;
}

// How to calculate the initial state?
export const ViewContextStore = createContext(null);

const matrixWidth = 3; // Defines the Compact view height and width in terms of chart count in each direction.

/*
Below is the modified code handling navigation around the 3x3 matrix excluding 9th element:
When the direction would normally take you to the 9th element, it skips it and goes to the next in line.
This applies to all directions Up, Down, Left, Right.

The Idea is just to add an extra step away from the Excluded Index when we encounter indexes leading to it.
*/
let totalCells = matrixWidth ** 2;
let ExcludedIndex = 8; // this is that 9th cell which we want to exclude
const getBelowIndex = (currentIndex) => {
    let newIndex = (currentIndex + matrixWidth) % totalCells;
    return newIndex !== ExcludedIndex ? newIndex : getBelowIndex(newIndex); // if index is excluded one, calculate for the next one
}
const getAboveIndex = (currentIndex) => {
    let newIndex = (currentIndex - matrixWidth + totalCells) % totalCells;
    return newIndex !== ExcludedIndex ? newIndex : getAboveIndex(newIndex); // if index is excluded one, calculate for the next one
}
const getNextIndex = (currentIndex) => {
    let newIndex = (currentIndex + 1) % totalCells;
    return newIndex !== ExcludedIndex ? newIndex : getNextIndex(newIndex); // if index is excluded one, calculate for the next one
}
const getPreviousIndex = (currentIndex) => {
    let newIndex = (currentIndex - 1 + totalCells) % totalCells;
    return newIndex !== ExcludedIndex ? newIndex : getPreviousIndex(newIndex); // if index is excluded one, calculate for the next one
}

export function toggleSeriesForChartUsingMetadata(chartConfig, predicate, enableSeries, showWeeklySeries) {
    toggleSeriesForChartUsingMetadataExplicit(chartConfig.seriesConfig, chartConfig.seriesMetadata, predicate, enableSeries, showWeeklySeries);
}

export const toggleSeriesForChartUsingMetadataExplicit = (seriesConfig, metadata, predicate, enableSeries, showWeeklySeries) => {
    if (enableSeries == null) return;
    metadata?.forEach(seriesMetadata => {

        //Show weekly needs to be factored in when enabling series(case: weekly off, and turn off a set), but not when a series set is being disabled:
        const predicateResult = enableSeries ? (showWeeklySeries ? predicate(seriesMetadata, seriesConfig) : predicate(seriesMetadata, seriesConfig) && weeklyPredicate(seriesMetadata, showWeeklySeries)) : predicate(seriesMetadata, seriesConfig);

        if (predicateResult) {
            // Series is a candidate for toggle:
            seriesConfig[seriesMetadata.seriesName] = {
                visible: enableSeries,
                yAxisVisible: enableSeries && seriesConfig[seriesMetadata.seriesName]?.yAxisVisible
            };
        }
    })
}

export const bulkFlipSeriesUsingMetadata = (enableSeries, predicate, state, showWeeklySeries) => {
    if (enableSeries === null) return;
    const newState = _.cloneDeep(state);
    Object.keys(newState).forEach(chartName => {
        const chartConfig = newState[chartName];
        toggleSeriesForChartUsingMetadata(chartConfig, predicate, enableSeries, showWeeklySeries);
    });
    return newState;
}


export const ViewContextProvider = memo(({tick, user, children, brand, combineAxes = false}) => {
    const [viewState, setViewState] = useState(viewContextStoreBuilder(combineAxes));
    const [modal3x3State, setModal3x3State] = useState({
        open: false,
        index: 0
    });

    const [wtdStackModalOpen, setWtdStackModalOpen] = useState(false);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const [preProjectionsAvailable, setPreProjectionsAvailable] = useState(false);

    const [mode, setMode] = useState(MODES.STICKY);

    const toggle3x3Modal = (index) => {
        setModal3x3State(prev => ({
            index: index,
            open: !prev.open
        }));
    };

    const [metadataMap, setMetadataMap] = useState({});

    useEffect(() => {
        // Reset the view on a tick change.
        setMetadataMap({});// Empty out the metadata map, and have it repopulate with the reversePopulate logic.
        if (mode === MODES.DEFAULT) setViewState(viewContextStoreBuilder());
        // Todo: add logic for fetching the initial view state from the backend + populate it if default is empty.
    }, [tick, brand]);

    const reversePopulateChartConfig = (chartName, seriesConfig, metadata) => {

        setMetadataMap(prev => ({
            ...prev,
            [chartName]: metadata
        }));

        // If mode is sticky and there was a ticker active before this:
        if (mode === MODES.STICKY) {
            const currentChartState = predicateExclusiveMode ? versionedViewState[chartName] : viewState[chartName];
            Object.keys(seriesConfig).forEach(seriesName => {
                if (currentChartState != null) {
                    // Copy the current setting if the series matches
                    const currentSeriesConfig = currentChartState.seriesConfig[seriesName];
                    if (currentSeriesConfig != null) {
                        seriesConfig[seriesName] = currentSeriesConfig
                    }
                }
            });
            // Apply all the shortcuts, with a mutating function, align what the function consumes:
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, appSeriesPredicate, appSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, ccSeriesPredicate, ccSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, gtSeriesPredicate, gtSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, similarWebPredicate, swSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, segmentSeriesPredicate, segmentsSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, yipitPredicate, yipitSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, placerPredicate, placerSeriesBulkToggle, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, indexesPredicate, indexSeriesBulkToggle, showWeeklySeries);
            // Weekly should always be applied last, since it is dependent on the state mutations that were done above:
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, oracleIndexPredicate, !showWeeklySeries, showWeeklySeries);
            toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, weekliesButtonPredicate, showWeeklySeries, showWeeklySeries);


            if (predicateExclusiveMode) {
                //Save the currently created config to the versioned store:
                const newSeriesConfig = {...seriesConfig};
                setVersionedViewState(prev => {
                        return ({
                            ...prev,
                            [chartName]: {
                                ...prev[chartName],
                                seriesConfig: newSeriesConfig,
                                seriesMetadata: metadata != null ? metadata : prev[chartName]?.seriesMetadata,
                            }
                        })
                    }
                );

                //Do the changes based on the exclusive modes:
                toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, inversePredicate(BUTTON_TO_PREDICATE_MAP[predicateModeActivePredicate]), false, showWeeklySeries);
                toggleSeriesForChartUsingMetadataExplicit(seriesConfig, metadata, BUTTON_TO_PREDICATE_MAP[predicateModeActivePredicate], true, showWeeklySeries);
            }

        }

        setViewState(prev => ({
            ...prev,
            [chartName]: {
                ...prev[chartName],
                seriesConfig,
                seriesMetadata: metadata != null ? metadata : prev[chartName]?.seriesMetadata,
                combineAxes: combineAxesBulkToggle
            }
        }));
    }

    const modifyViewStateWithObjectForSeriesAndChart = (chartName, seriesName, modifiedObject) => {
        setViewState(prevState => ({
            ...prevState,
            [chartName]: {
                ...prevState[chartName],
                seriesConfig: {
                    ...prevState[chartName].seriesConfig,
                    [seriesName]: modifiedObject,
                },
            },
        }));
    }

    const updateChartRange = (chartName, extremities) => {
        setViewState(prevState => {
            return {
                ...prevState,
                [chartName]: {
                    ...prevState[chartName],
                    extremities: extremities,
                },
            };
        });
    }

    const clearAllVisibleAxisForChart = (chartName) => {
        const newStateObjectForSeries = viewState[chartName].seriesConfig;
        Object.keys(newStateObjectForSeries).forEach((seriesName) => {
            newStateObjectForSeries[seriesName].yAxisVisible = false
        });
        setViewState(prevState => ({
            ...prevState,
            [chartName]: {
                ...prevState[chartName],
                seriesConfig: newStateObjectForSeries
            },
        }));
    }

    const disableAllSeriesNotInListForChart = (chartName, seriesNameList) => {
        const newStateObjectForSeries = viewState[chartName].seriesConfig;
        Object.keys(newStateObjectForSeries).forEach((seriesName) => {
            newStateObjectForSeries[seriesName] = {
                yAxisVisible: newStateObjectForSeries[seriesName].yAxisVisible && _.findIndex(seriesNameList, name => name === seriesName) > 0,
                visible: _.findIndex(seriesNameList, name => name === seriesName) > 0
            }
        });
        setViewState(prevState => ({
            ...prevState,
            [chartName]: {
                ...prevState[chartName],
                seriesConfig: newStateObjectForSeries
            },
        }));
    }
// APP, cc, GT, SW toggles:
    // Store if the next toggle will be disabling(toggle down) or enabling:
    const [appSeriesBulkToggle, setAppSeriesBulkToggle] = useState(null);
    const [ccSeriesBulkToggle, setCcSeriesBulkToggle] = useState(null);
    const [gtSeriesBulkToggle, setGtSeriesBulkToggle] = useState(null);
    const [swSeriesBulkToggle, setSwSeriesBulkToggle] = useState(null);
    const [segmentsSeriesBulkToggle, setSegmentsSeriesBulkToggle] = useState(null);
    const [yipitSeriesBulkToggle, setYipitSeriesBulkToggle] = useState(null);
    const [placerSeriesBulkToggle, setPlacerSeriesBulkToggle] = useState(null);
    const [indexSeriesBulkToggle, setIndexSeriesBulkToggle] = useState(null);
    const [combineAxesBulkToggle, setCombineAxesBulkToggle] = useState(false);

    const [idioBulkToggle, setIdioBulkToggle] = useState(null);
    const [siBulkToggle, setSiBulkToggle] = useState(null);
    const [idioSiBulkToggle, setIdioSiBulkToggle] = useState(null);

    const [isDailyStackAlignment, setDailyStackAlignment] = useState(false);

    const [showWeeklySeries, setShowWeeklySeries] = useState(false);
    const [showPeerIndices, setShowPeerIndices] = useState(false);

    const toggleCombineAxisBulk = createToggleFunction(setCombineAxesBulkToggle);

    const toggleAppSeriesBulk = createToggleFunction(setAppSeriesBulkToggle);

    const toggleCCSeriesBulk = createToggleFunction(setCcSeriesBulkToggle);

    const toggleGTSeriesBulk = createToggleFunction(setGtSeriesBulkToggle);

    const toggleSwSeriesBulk = createToggleFunction(setSwSeriesBulkToggle);

    const toggleSegmentSeriesBulk = createToggleFunction(setSegmentsSeriesBulkToggle);

    const toggleYipitSeriesBulk = createToggleFunction(setYipitSeriesBulkToggle);

    const togglePlacerSeriesBulk = createToggleFunction(setPlacerSeriesBulkToggle);

    const toggleIndexSeriesBulk = createToggleFunction(setIndexSeriesBulkToggle);

    const toggleIdioBulk = createToggleFunction(setIdioBulkToggle);

    const toggleSiBulk = createToggleFunction(setSiBulkToggle);

    const toggleIdioSiBulk = createToggleFunction(setIdioSiBulkToggle);

    function bulkToggleSeriesUsingMetadata(enableSeries, predicate) {
        if (enableSeries == null) return; // Effect should not run, unless triggered by user.
        // Set to the correct value for each chart:
        setViewState(prev => bulkFlipSeriesUsingMetadata(enableSeries, predicate, prev, showWeeklySeries));
    }

    useEffect(() => {
        const enableCombineAxes = combineAxesBulkToggle;
        setViewState(prev => {
            const newObject = {...prev};
            Object.keys(newObject).forEach((chartName) => {
                // Levels charts should not have combine axis enabled.
                if (!chartName.toLowerCase().includes('level')) {
                    newObject[chartName].combineAxes = enableCombineAxes;
                    if (combineAxesBulkToggle) {
                        toggleSeriesForChartUsingMetadata(newObject[chartName], siPredicate, true);
                        if (newObject[chartName].seriesConfig.idio != null)
                            newObject[chartName].seriesConfig.idio.visible = true;

                    }
                }
            });
            return newObject;
        });
    }, [combineAxesBulkToggle]);

    useEffect(() => {
        // If next toggle will be disabling, current action should enable it:
        bulkToggleSeriesUsingMetadata(appSeriesBulkToggle, appSeriesPredicate);
    }, [appSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(ccSeriesBulkToggle, ccSeriesPredicate);
    }, [ccSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(gtSeriesBulkToggle, gtSeriesPredicate);
    }, [gtSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(swSeriesBulkToggle, similarWebPredicate)
    }, [swSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(segmentsSeriesBulkToggle, segmentSeriesPredicate)
    }, [segmentsSeriesBulkToggle]);

    useEffect(() =>  {
        bulkToggleSeriesUsingMetadata(idioBulkToggle, idioPredicate)
    }, [idioBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(siBulkToggle, siPredicate)
    }, [siBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(idioSiBulkToggle, combinePredicatesOr(idioPredicate, siPredicate));
    }, [idioSiBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(yipitSeriesBulkToggle, yipitPredicate);
    }, [yipitSeriesBulkToggle]);

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(placerSeriesBulkToggle, placerPredicate);
    }, [placerSeriesBulkToggle])

    useEffect(() => {
        bulkToggleSeriesUsingMetadata(indexSeriesBulkToggle, indexesPredicate);
    }, [indexSeriesBulkToggle])

    useEffect(() => {
        if (showWeeklySeries) {
            // disable app and placer:
            setAppSeriesBulkToggle(false);
            setPlacerSeriesBulkToggle(false);
            setSwSeriesBulkToggle(false);
        }
        bulkToggleSeriesUsingMetadata(!showWeeklySeries, oracleIndexPredicate);
        bulkToggleSeriesUsingMetadata(showWeeklySeries, weekliesButtonPredicate);
    }, [showWeeklySeries])

    //Code for the functionality to disable all series apart from those matching a predicate, with a versioning system
    // on the view state to preserve the old state and revert to it on disabling of the feature.

    const [versionedViewState, setVersionedViewState] = useState({});
    const [predicateExclusiveMode, setPredicateExclusiveMode] = useState(false);
    const [predicateModeActivePredicate, setPredicateModeActivePredicate] = useState(null);

    const switchToPredicateExclusiveModeWithPredicate = (predicate, predicateDescriptor, all) => {
        //Save the old version:
        if (!predicateExclusiveMode) setVersionedViewState(viewState);
        //Switch on the predicate exclusive mode
        setPredicateExclusiveMode(true);
        const finalPredicateDescriptor = all ? predicateToAllTransformer(predicateDescriptor) : predicateDescriptor;
        setPredicateModeActivePredicate(finalPredicateDescriptor);

        setViewState(prev => {
            const disabledSeriesState = all ? bulkFlipSeriesUsingMetadata(false, inversePredicate(predicate), prev, showWeeklySeries)
                : bulkFlipSeriesUsingMetadata(false, combinePredicates(inversePredicate(predicate), inversePredicate(kpiPredicate)), prev, showWeeklySeries);
            return bulkFlipSeriesUsingMetadata(true, predicate, disabledSeriesState, showWeeklySeries)
        });

    }

    const disablePredicateExclusiveMode = () => {
        setViewState(prev => {
            const newObject = Object.assign({}, prev);
            Object.keys(newObject).forEach((chartName) => {
                //Todo: Integrate with a more comprehensive merging logic instead of overwrites:
                newObject[chartName].seriesConfig = versionedViewState[chartName].seriesConfig;
            });
            return newObject;
        });
        setVersionedViewState({});
        setPredicateExclusiveMode(false);
        setPredicateModeActivePredicate(null);
    }

    const getFlipExclusiveModeForSeriesFunction = (predicate, predicateDescriptor, all) => () => {
        const finalPredicateDescriptor = all ? predicateToAllTransformer(predicateDescriptor) : predicateDescriptor;
        if (predicateExclusiveMode && predicateModeActivePredicate === finalPredicateDescriptor) {
            disablePredicateExclusiveMode();
        } else {
            switchToPredicateExclusiveModeWithPredicate(predicate, predicateDescriptor, all);
        }
    }

    // Logic for handling arrow keys based toggling of the modal open chart in the mini view:
    const goAbove = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if (!prev.open) return prev;
            return {
                ...prev,
                index: getAboveIndex(prev.index)
            }
        });
    }

    const goBelow = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if (!prev.open) return prev;
            return {
                ...prev,
                index: getBelowIndex(prev.index)
            }
        });
    }

    const goNext = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if (!prev.open) return prev;
            return {
                ...prev,
                index: getNextIndex(prev.index)
            }
        });
    }

    const goPrev = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            if (!prev.open) return prev;
            return {
                ...prev,
                index: getPreviousIndex(prev.index)
            }
        });
    }

    const toggleOffModal = (e) => {
        if (modal3x3State.open) e.preventDefault();
        setModal3x3State(prev => {
            return {
                ...prev,
                open: false
            }
        });
    }

    const [keysPressed, setKeysPressed] = useState({zKey: false, xKey: false, cKey: false, vKey: false});

    const [dropLastPoint, setDropLastPoint] = useState(false);


    useEffect(() => {
        const handleKeyUp = (event) => {
            const newKeysStatus = {...keysPressed};
            if (['z', 'x', 'c', 'v'].includes(event.key)) {
                newKeysStatus[`${event.key}Key`] = false;
                setKeysPressed(newKeysStatus);
            }
        };
        document.addEventListener("keyup", handleKeyUp);
        return () => document.removeEventListener("keyup", handleKeyUp);
    }, []);

    const [viewSettingsModalIsOpen, setViewSettingsModalIsOpen]
        = useState(false);
    const toggleViewSettingsModal = () => setViewSettingsModalIsOpen(prev => !prev);
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.key === 'd') {
                e.preventDefault();
                setDailyStackAlignment(prev => !prev);
            }

            if (e.ctrlKey && e.key === 'a') {
                e.preventDefault();
                setCombineAxesWithSeriesSetup(prev => !prev);
            }

            if (e.ctrlKey && e.key === 'i') {
                e.preventDefault();
                setShowPeerIndices(prev => !prev);
            }

            if (e.ctrlKey && e.key === 'g') {
                e.preventDefault();
                toggleGTSeriesBulk()
            }

            if (e.ctrlKey && e.key === 'k') {
                e.preventDefault();
                toggleAppSeriesBulk()
            }

            if (e.ctrlKey && e.key === 'r') {
                e.preventDefault();
                toggleCCSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'w') {
                e.preventDefault();
                toggleSwSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'e') {
                e.preventDefault();
                toggleSegmentSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'o') {
                e.preventDefault();
                toggleCombineAxisBulk();
            }

            if (e.ctrlKey && e.key === 'p') {
                e.preventDefault();
                togglePlacerSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'y') {
                e.preventDefault();
                toggleYipitSeriesBulk();
            }

            if (e.ctrlKey && e.key === 'x') {
                e.preventDefault();
                toggleIndexSeriesBulk();
            }

            if (e.ctrlKey && e.key === '/') {
                e.preventDefault();
                if (mode === MODES.STICKY) setMode(MODES.DEFAULT);
                else if (mode === MODES.DEFAULT) setMode(MODES.STICKY);
            }

            if (e.ctrlKey && e.key === '\\') {
                e.preventDefault();
                toggleViewSettingsModal();
            }

            if (e.ctrlKey && e.key === ';') {
                e.preventDefault();
                console.log('Changing drop last point')
                setDropLastPoint(prev => !prev);
            }

            if (/*e.ctrlKey &&*/ e.key === 'ArrowUp') {
                goAbove(e);
            }

            if (/*e.ctrlKey &&*/ e.key === 'ArrowDown') {
                goBelow(e);
            }

            if (/*e.ctrlKey &&*/ e.key === 'ArrowRight') {
                goNext(e);
            }

            if (/*e.ctrlKey &&*/ e.key === 'ArrowLeft') {
                goPrev(e);
            }

            if (e.keyCode === 27) {
                toggleOffModal(e);
            }

            if (e.ctrlKey && e.key === 't') {
                e.preventDefault();
                setWtdStackModalOpen(prev => !prev);
            }

            if (e.key === 'F1') {
                e.preventDefault();
                setChatModalOpen(prev => !prev);
            }

            const newKeysStatus = {...keysPressed};
            if (['z', 'x', 'c', 'v'].includes(e.key)) {
                newKeysStatus[`${e.key}Key`] = true;
                setKeysPressed(newKeysStatus);
            }

        };

        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [modal3x3State]);

    // Wrap this in a hoc and pass that to the individual charts:
    const modifyActiveSeries = (queryType, seriesName) => {

        if (keysPressed.vKey) {
            // Toggle the particular series across all charts which have those series visible:
            setViewState(prev => {
                // Find the current state of the series in the specific chart:
                const currentVisibility = prev[queryType].seriesConfig[seriesName]?.visible;
                if (currentVisibility == null) return prev; // Unreachable code, but safe.
                const newState = {...prev};
                const targetVisibility = !currentVisibility;
                Object.keys(newState).filter(key => newState[key].seriesConfig[seriesName] != null).forEach(chart => {
                    newState[chart].seriesConfig[seriesName].visible = targetVisibility;
                    // Y axis should be visible only if the axis was previous enabled and the series is visible:
                    newState[chart].seriesConfig[seriesName].yAxisVisible = targetVisibility && newState[chart].seriesConfig[seriesName].yAxisVisible;
                });
                return newState;
            });
            return;
        }

        if (keysPressed.xKey) {
            // toggle y-axis visibility of the series, and make series itself visible:
            const newObjectForSeries = {
                ...viewState[queryType].seriesConfig[seriesName]
            };
            newObjectForSeries.visible = true;
            newObjectForSeries.yAxisVisible = !newObjectForSeries.yAxisVisible;
            modifyViewStateWithObjectForSeriesAndChart(queryType, seriesName, newObjectForSeries);
            return;
        }
        if (keysPressed.cKey) {
            // Disable all y axis visible for all the series:
            clearAllVisibleAxisForChart(queryType);
            return;
        }
        if (keysPressed.zKey) {
            // Disable all series apart from current series and the following:
            // 'ghost' and 'ghost_daily' -> Required for preserving scaling and other things
            // 'idio' should always be enabled, unless user explicitly closes it:
            const seriesToKeepVisible = seriesName === 'idio' ? ['ghost', 'ghost_daily', 'idio'] : ['ghost', 'ghost_daily', 'idio', seriesName];
            disableAllSeriesNotInListForChart(queryType, seriesToKeepVisible);
            return;
        }
        // No key is pressed, simply toggle the values:
        const modifiedSeriesObject = {
            yAxisVisible: viewState[queryType].seriesConfig[seriesName]?.yAxisVisible,
            visible: viewState[queryType].seriesConfig[seriesName]?.visible
        }
        if (modifiedSeriesObject.visible) {
            // disable yAxis as well:
            modifiedSeriesObject.yAxisVisible = false;
            modifiedSeriesObject.visible = false;
        } else {
            modifiedSeriesObject.visible = true;
        }
        modifyViewStateWithObjectForSeriesAndChart(queryType, seriesName, modifiedSeriesObject);
    }

    const setCombineAxes = (chartName, combineAxes) => {
        setViewState(prev => ({
            ...prev,
            [chartName]: {
                ...prev[chartName],
                combineAxes
            }
        }))
    }

    const setDynamicLag = (chartName, dynLag) => {
        setViewState(prev => ({
            ...prev,
            [chartName]: {
                ...prev[chartName],
                dynamicLag: dynLag
            }
        }))
    }

    const setSeriesMetaData = (chartName, seriesMetaData) => {
        setViewState(prev => ({
            ...prev,
            [chartName]: {
                ...prev[chartName],
                seriesMetadata: seriesMetaData
            }
        }))
    }

    const shortcutTogglesAndState = {
        [CC]: {
            state: ccSeriesBulkToggle,
            trigger: toggleCCSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(ccSeriesPredicate, CC, false),
            excModeDataActive: predicateModeActivePredicate === CC,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(ccSeriesPredicate, CC, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(CC)
        },
        [GT]: {
            state: gtSeriesBulkToggle,
            trigger: toggleGTSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(gtSeriesPredicate, GT, false),
            excModeDataActive: predicateModeActivePredicate === GT,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(gtSeriesPredicate, GT, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(GT)
        },
        [SW]: {
            state: swSeriesBulkToggle,
            trigger: toggleSwSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(similarWebPredicate, SW, false),
            excModeDataActive: predicateModeActivePredicate === SW,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(similarWebPredicate, SW, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(SW)
        },
        [APP]: {
            state: appSeriesBulkToggle,
            trigger: toggleAppSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(appSeriesPredicate, APP, false),
            excModeDataActive: predicateModeActivePredicate === APP,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(appSeriesPredicate, APP, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(APP)
        },
        [SEGMENT]: {
            state: segmentsSeriesBulkToggle,
            trigger: toggleSegmentSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(segmentSeriesPredicate, SEGMENT, false),
            excModeDataActive: predicateModeActivePredicate === SEGMENT,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(segmentSeriesPredicate, SEGMENT, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(SEGMENT)
        },
        [PLACER]: {
            state: placerSeriesBulkToggle,
            trigger: togglePlacerSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(placerPredicate, PLACER, false),
            excModeDataActive: predicateModeActivePredicate === PLACER,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(placerPredicate, PLACER, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(PLACER)
        },
        [YIPIT]: {
            state: yipitSeriesBulkToggle,
            trigger: toggleYipitSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(yipitPredicate, YIPIT, false),
            excModeDataActive: predicateModeActivePredicate === YIPIT,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(yipitPredicate, YIPIT, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(YIPIT)
        },
        [INDICES]: {
            state: indexSeriesBulkToggle,
            trigger: toggleIndexSeriesBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(indexesPredicate, INDICES, false),
            excModeDataActive: predicateModeActivePredicate === INDICES,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(indexesPredicate, INDICES, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(INDICES)
        },
        [IDIO]: {
            state: idioBulkToggle,
            trigger: toggleIdioBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(idioPredicate, IDIO, false),
            excDataActive: predicateModeActivePredicate === IDIO,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(idioPredicate, IDIO, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(IDIO),
        },
        [SI]: {
            state: siBulkToggle,
            trigger: toggleSiBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(siPredicate, SI, false),
            excModeDataActive: predicateModeActivePredicate === SI,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(siPredicate, SI, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(SI),
        },
        [IDIO_SI]: {
            state: idioSiBulkToggle,
            trigger: toggleIdioSiBulk,
            excModeDataFunction: getFlipExclusiveModeForSeriesFunction(idioSiPredicate, IDIO_SI, false),
            excModeDataActive: predicateModeActivePredicate === IDIO_SI,
            excModeAllFunction: getFlipExclusiveModeForSeriesFunction(idioSiPredicate, IDIO_SI, true),
            excModeAllActive: predicateModeActivePredicate === predicateToAllTransformer(IDIO_SI),
        }
    }

    const completeMetadata = Object.fromEntries(
        Object.entries(viewState).map(([key, value]) => [key, value.seriesMetadata])
    );

    const bulkToggle = (seriesList, chartsList, isEnableSeries, isEnableAxis) => {
        setViewState(prev => {
            const newObj = {...prev}
            chartsList.forEach(chart => {
                const chartConfig = newObj[chart].seriesConfig;
                seriesList.forEach(series => {
                    if (chartConfig[series])
                        chartConfig[series] = {
                            visible: isEnableSeries,
                            yAxisVisible: isEnableAxis,
                        }
                })
            });
            return newObj;
        })
    }

    const bulkToggleChartSettings = (chartsList, isEnableCombineAxis) => {
        setViewState(prev => {
            const newObj = {...prev};
            chartsList.forEach(chart => {
                newObj[chart].combineAxes = isEnableCombineAxis;
            });
            return newObj;
        })
    }




    return (
        <ViewContextStore.Provider value={{
            viewState,
            modifyActiveSeries,
            reversePopulateChartConfig,
            updateChartRange,
            modifyViewStateWithObjectForSeriesAndChart,
            isDailyStackAlignment,
            setDailyStackAlignment,
            combineAxesBulkToggle,
            toggleCombineAxisBulk,
            showWeeklySeries,
            setShowWeeklySeries,
            showPeerIndices,
            setShowPeerIndices,
            setCombineAxes,
            setDynamicLag,
            setSeriesMetaData,
            shortcutTogglesAndState,
            modal3x3State,
            toggle3x3Modal,
            mode,
            setMode,
            viewSettingsModalIsOpen,
            toggleViewSettingsModal,
            completeMetadata,
            bulkToggle,
            bulkToggleChartSettings,
            dropLastPoint,
            setDropLastPoint,
            wtdStackModalOpen,
            setWtdStackModalOpen,
            chatModalOpen,
            setChatModalOpen,
            metadataMap,
            tick,
            brand
        }}>
            {children}
        </ViewContextStore.Provider>
    )
})

//Todo: Move this to a dedicated file with descriptive comments:/**/
export const SplitContextsConsumerWrapper = (props) => {
    const completeViewContextData = useContext(ViewContextStore);
    const {dataState} = useContext(DashboardApiContextStore);
    //Modify the query to reflect correctly if the DailyStackAlignment is set:
    const queryType = completeViewContextData.isDailyStackAlignment ? DailyStackAlignmentDependentCharts.includes(props.queryType) ? props.queryType + '_ALIGNED' : props.queryType : props.queryType;
    // if(props.queryType == null) return props.RawComponent;
    const splitData = dataState[queryType];
    const chartData = splitData?.chartData;
    const idioData = dataState[DashboardQueryTypes.IDIO]?.chartData?.data;
    const viewData = completeViewContextData.viewState == null ? null : completeViewContextData?.viewState[props.queryType];
    const compactViewQueryIndex = _.indexOf(COMPACT_VIEW_QUERY_LIST, queryType);
    const compactViewModalState = compactViewQueryIndex === -1 ? false : (compactViewQueryIndex === completeViewContextData.modal3x3State.index) && completeViewContextData.modal3x3State.open;
    const compactViewToggleModal = compactViewQueryIndex === -1 ? () => {
    } : () => completeViewContextData.toggle3x3Modal(compactViewQueryIndex);
    const chartToggleConfig = {
        viewData,
        modifyActiveSeries: (seriesName) => completeViewContextData?.modifyActiveSeries(props.queryType, seriesName),
        updateChartRange: (extremities) => completeViewContextData?.updateChartRange(props.queryType, extremities),
        setCombineAxes: (combineAxes) => completeViewContextData?.setCombineAxes(props.queryType, combineAxes),
        setDynamicLag: (dynLag) => completeViewContextData?.setDynamicLag(props.queryType, dynLag),
        compactViewModalState,
        compactViewToggleModal,
        dropLastPoint: completeViewContextData.dropLastPoint,

    };
    const toggleOptionsConfig = {
        isDailyStackAlignment: completeViewContextData.isDailyStackAlignment,
        setDailyStackAlignment: completeViewContextData.setDailyStackAlignment,
        showWeeklySeries: completeViewContextData.showWeeklySeries,
        setShowWeeklySeries: completeViewContextData.setShowWeeklySeries,
    }

    return (cloneElement(props.RawComponent, {
        chartData: chartData,
        loading: splitData?.loading,
        isError: splitData?.isError,
        chartToggleConfig,
        toggleOptionsConfig,
        idioData,
        ...props
    }))
}