import {useContext, useEffect} from "react";
import {ProjectionsChartGrid} from "./ProjectionsChartGrid";
import {ProjectionsAdderGrid} from "./PositionsAdderGrid";
import {ProjectionsInfoContext} from "./ProjectionsInfoProvider";
import {ViewContextStore} from "../views/ViewContext";


export const ProjectionsScreen = () => {

    const {
        availableProjectionsUpdated,
        chartLayout,
        addProjections,
        removeProjections,
        addableProjections
    } = useContext(ProjectionsInfoContext);

    const {
        toggleCombineAxisBulk,
        setShowWeeklySeries
    } = useContext(ViewContextStore);

    useEffect(() => {
        toggleCombineAxisBulk();
        setShowWeeklySeries(true);
    }, [])

    return (
        <>
            {availableProjectionsUpdated &&
                <> {chartLayout.map(chartLayout => chartLayout.rhs != null || chartLayout.lhs != null ?
                    <ProjectionsChartGrid
                        chartLayout={chartLayout}
                        key={'ProjectionsChartLayout' + chartLayout.rhs?.seriesName + chartLayout.lhs?.seriesName}
                        removeProjections={removeProjections}
                    /> : <></>
                )}
                    <ProjectionsAdderGrid addableProjections={addableProjections} addProjection={addProjections}/>
                </>
            }
            {!availableProjectionsUpdated &&
                <h1>Please wait. Available projections have not yet been determined yet.</h1>
            }
        </>
    )
}