import {createContext, useContext, useEffect, useRef, useState} from "react";
import {message} from "antd";
import {getTickInfo, getWatchListItems, getWatchLists} from "../../../api/data/DataProvider";
import {ALL, LONG, SHORT, WATCHLISTS} from "../compactView/ListSelectionBar";
import _ from "lodash";
import {getAllPositions, getLatestPerformanceDate} from "../../../api/data/PortfolioDataProvider";
import {getIndex} from "../commonUtils/WatchListBarUtilFunctions";
import {RefreshContext} from "./RefreshContextProvider";
import {useNavigate} from "react-router-dom";


export const ListsDataContext = createContext();
export const ListsProvider = ({children, setActiveTick, activeTick, showMiniView, setShowMiniView}) => {

    const {refresh} = useContext(RefreshContext);

    const [messageApi, contextHolder] = message.useMessage();
    // ToDo: Decompose to independent data provider:
    const [watchLists, setWatchLists] = useState([]);
    const [activeList, setActiveList] = useState(null);
    const [loadingWatchList, setLoadingWatchList] = useState(false);
    const [scrollTicks, setScrollTicks] = useState([]);
    const [tickInfo, setTickInfo] = useState([]);
    const [activeSelector, setActiveSelector] = useState(null);
    const [index, setIndex] = useState(-1);
    const [listLength, setListLength] = useState(0);

    const [goToNext, setGoToNext] = useState(null);
    const [goToPrev, setGoToPrev] = useState(null);
    const [goToIndex, setGoToIndex] = useState(null);
    const [positions, setPositions] = useState([]);
    const [longPositions, setLongPositions] = useState([]);
    const [shortPositions, setShortPositions] = useState([]);
    const [browserParamsUsed, setBrowserParamsUsed] = useState(false);
    const [scrollTicksName, setScrollTicksName] = useState(null);
    const [list, setList] = useState([]);
    // Load the tickers in a WL, when the active list changes:
    useEffect(() => {
        const abortController = new AbortController();
        if (activeList != null) {

            getWatchListItems(activeList, abortController)
                .then((res) => {
                    let tickerList = res.data.map(item => {
                        return {
                            tickerId: item.tickerId,
                            studioTicker: item.tick
                        };
                    }).filter(item => item.tickerId != null);

                    setScrollTicks(tickerList);
                    getTickInfos(tickerList);
                    setScrollTicksName(activeList);
                })
                .catch((err) => {
                    if (err.code === "ERR_CANCELED") {
                        return;
                    }
                    console.error("Failed to load the selected watchlist", err);
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to load the selected watchlist. Please refresh to try again.',
                    });
                })
        }
        return () => abortController.abort();
    }, [activeList, refresh]);

    // Load all the positions and assign to the relevant lists:
    useEffect(() => {
        const abortController = new AbortController();
        getLatestPerformanceDate(abortController)
            .then((res) => {
                const resultDate = res.data;
                return getAllPositions(resultDate, [], abortController)
            })
            .then((res) => {
                let data = res.data;
                data = data.filter(position => position.tickerId != null);
                data.sort((a, b) => Math.abs(b.ratioOfNav) - Math.abs(a.ratioOfNav))
                setPositions(data);
                setShortPositions(data.filter(position => position.position < 0));
                setLongPositions(data.filter(position => position.position > 0));
            })
            .catch((err) => {
                console.error("Failed to get all positions!", err);
                messageApi.open({
                    type: 'Error',
                    content: 'Failed to fetch positions. Please refresh to try again.',
                });
            })
        return () => abortController.abort();
    }, [refresh]);

    const navigate = useNavigate();

    useEffect(() => {
        setBrowserParamsUsed(true);
        const params = new URLSearchParams(window.location.search);
        const paramsActiveSelector = params.get('activeSelector');
        if (paramsActiveSelector != null) {
            setActiveSelector(paramsActiveSelector.toUpperCase());
            if (paramsActiveSelector.toUpperCase() === WATCHLISTS) {
                const paramsActiveList = params.get('activeList');
                if (paramsActiveList != null) setActiveList(paramsActiveList);
            }
        }
    }, []);

    useEffect(() => {
        if (!browserParamsUsed) return;
        const params = new URLSearchParams(window.location.search);
        //Todo; this is def unecessary render:
        if (activeSelector == null) {
            params.delete('activeSelector');
            params.delete('activeList');
            navigate(window.location.pathname + '?' + params.toString());
            return;
        }
        params.set('activeSelector', activeSelector);
        if (activeSelector === WATCHLISTS) {
            params.set('activeList', activeList)
        } else {
            params.delete('activeList');
        }
        navigate(window.location.pathname + '?' + params.toString());
    }, [activeSelector, activeList]);
    // Load all the list names:
    useEffect(() => {
        const abortController = new AbortController();
        setLoadingWatchList(true);
        getWatchLists(abortController)
            .then((res) => {
                let items = res.data.map(item => {
                    return {
                        value: item,
                        label: item.replace("SPL||", "")
                    };
                })
                setWatchLists(items);
                setLoadingWatchList(false);
            })
            .catch((err) => {
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                }
                setLoadingWatchList(false);
            })

        return () => abortController.abort();
    }, [refresh]);


    const getTickInfos = (tickerIds) => {
        let filteredTickerIds = tickerIds.filter(tickerId => tickerId != null)
        getTickInfo(filteredTickerIds)
            .then((res) => {
                setTickInfo(res.data);
            }).catch((err) => {
            // do nothing
        })
    }

    const loadTick = (value) => {
        let ticker = value.target.innerText;
        let tickerId = -1;
        for (let i = 0; i < tickInfo.length; i++) {
            if (tickInfo[i].tickerName === ticker) {
                tickerId = tickInfo[i].tickerId;
            }
        }

        if (ticker !== undefined && ticker !== "" && tickerId !== -1) {
            setActiveTick({
                name: ticker,
                id: tickerId
            });
        }
    }


    const mapNameToList = (listName) => {
        switch (listName) {
            case ALL:
                return positions;
            case LONG:
                return longPositions;
            case SHORT:
                return shortPositions;
        }
    }

    /*
    * Performance optimization:
    * We need to set the goTo functions for when the ticker changes, and when the list being used changes.
    * List changes: User might already be on Ticker, when they activate the list -> list base useEffect creates the funcs
    * Ticker changes(moving in the list included): activeTicker based useEffect changes the list.
    * When the list is selected for first time, both of the above will run.
    * I don't want a redundant function creation in the above case, which can be skipped by setting the localTick in
    * list based useEffects. If the same ticker was used to create the effects, I don't want to re-register keydowns, since
    * they are generally expensive.
    * */
    const [localTick, setLocalTick] = useState(null);

    // Stores the last registered Handler, so that it can be always unregistered, since multiple different effect have
    // control over the handlers.
    const keyDownRef = useRef(null);

    useEffect(() => {
        if (activeSelector == null || activeSelector !== WATCHLISTS) return;
        if (!scrollTicks?.some(tick => tick.tickerId === activeTick.id)) {
            if (scrollTicks.length <= 0) {
                messageApi.open({
                    type: 'warning',
                    content: 'No items in list!',
                });
                return;
            }

            messageApi.open({
                type: 'loading',
                content: 'Opening First in Line...',
            });

            const tick = scrollTicks[0];
            setActiveTick({
                name: tick.studioTicker,
                id: tick.tickerId
            })
            // Rely on the activeTick based useEffect to register the correct functions.
            setListLength(scrollTicks?.length);
            return;
        }
        let goToIndexInternal, goToNextInternal, goToPrevInternal;
        goToIndexInternal = (index) => {
            setActiveTick({
                name: scrollTicks[index].studioTicker,
                id: scrollTicks[index].tickerId
            });
        };

        goToNextInternal = () => {
            if (scrollTicks.length <= 0) {
                messageApi.open({
                    type: 'warning',
                    content: 'No items in list!',
                });
            }

            if (scrollTicks.length > 0) {
                messageApi.open({
                    type: 'loading',
                    content: 'Opening Next in Line...',
                });

                let currentIndex = -1;

                for (let i = 0; i < scrollTicks.length; i++) {
                    if (scrollTicks[i].tickerId === activeTick.id) {
                        currentIndex = i;
                        break;
                    }
                }


                let nextIndex = (currentIndex + 1) % scrollTicks.length;

                goToIndexInternal(nextIndex);
            }
        }

        goToPrevInternal = () => {
            if (scrollTicks.length <= 0) {
                messageApi.open({
                    type: 'warning',
                    content: 'No items in list!',
                });
            }

            if (scrollTicks.length > 0) {
                messageApi.open({
                    type: 'loading',
                    content: 'Opening Previous in Line...',
                });

                let currentIndex = -1;
                for (let i = 0; i < scrollTicks.length; i++) {
                    if (scrollTicks[i].tickerId === activeTick.id) {
                        currentIndex = i;
                        break;
                    }
                }

                let nextIndex = (scrollTicks.length + currentIndex - 1) % scrollTicks.length;

                goToIndexInternal(nextIndex);
            }
        }
        setIndex(getIndex(scrollTicks, activeTick));
        setGoToNext(() => goToNextInternal);
        setGoToPrev(() => goToPrevInternal);
        setGoToIndex(() => goToIndexInternal);
        setListLength(scrollTicks?.length);
        setList(scrollTicks.map(item => item.studioTicker));

        setLocalTick(activeTick);

        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.key === '.') {
                goToNextInternal();
            }
            if (e.ctrlKey && e.key === ',') {
                goToPrevInternal();
            }
        }

        window.removeEventListener('keydown', keyDownRef.current);


        keyDownRef.current = handleKeyPress;

        window.addEventListener('keydown', handleKeyPress);



    }, [scrollTicks]);

    useEffect(() => {
        if (activeSelector == null || activeSelector === WATCHLISTS) return;
        setScrollTicks([]);
        setScrollTicksName(null);
        let listCurrentlySelected = mapNameToList(activeSelector);
        setLocalTick(activeTick);
        if (!listCurrentlySelected?.some(ticker => ticker.tickerId === activeTick.id)) {
            if (listCurrentlySelected?.length > 0) {
                const position = listCurrentlySelected[0];
                setActiveTick({
                    name: position.studioTicker,
                    id: position.tickerId
                });
            }
            // All functions registered below will be registered with previous activeTick and will be overwritten in
            // the useEffect for activeTick change.
            setListLength(listCurrentlySelected?.length);
            return;
        }
        let goToIndexInternal, goToNextInternal, goToPrevInternal;

        goToIndexInternal = (index) => {
            const nextPosition = listCurrentlySelected[index];
            setActiveTick({
                name: nextPosition.studioTicker,
                id: nextPosition.tickerId
            });

        }

        goToNextInternal = () => {
            const listToUse = listCurrentlySelected;
            const currentIndex = _.findIndex(listToUse, position => position.tickerId === activeTick.id);
            const nextIndex = (currentIndex + 1) % listToUse?.length;
            goToIndexInternal(nextIndex)
        }
        goToPrevInternal = () => {
            const listToUse = listCurrentlySelected;
            const currentIndex = _.findIndex(listToUse, position => position.tickerId === activeTick.id);
            const prevIndex = (currentIndex - 1 + listToUse?.length) % listToUse?.length;

            goToIndexInternal(prevIndex);
        }
        setGoToNext(() => goToNextInternal);
        setGoToPrev(() => goToPrevInternal);
        setGoToIndex(() => goToIndexInternal);
        setListLength(listCurrentlySelected?.length);
        setIndex(getIndex(listCurrentlySelected, activeTick));
        setList(listCurrentlySelected.map(item => item.studioTicker));

        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.key === '.') {
                goToNextInternal();
            }
            if (e.ctrlKey && e.key === ',') {
                goToPrevInternal();
            }
        }

        window.removeEventListener('keydown', keyDownRef.current);

        window.addEventListener('keydown', handleKeyPress);
        keyDownRef.current = handleKeyPress;


    }, [activeSelector, positions, longPositions, shortPositions]); // Should be run when the target list changes.

    //Effect with the job of cleaning the list selection and other criteria,
    // if the user changes the active tick, outside the scope of the current list:
    //Also registers the new functions, whenever the currently active ticker changes.
    useEffect(() => {
        if (!browserParamsUsed || activeSelector == null) return;
        // Prevents the condition where this effect is run before the WL items load, and we incorrectly mark it for clearing:
        if ((activeSelector === WATCHLISTS && scrollTicksName !== activeList))
            return;
        const listCurrentlySelected = activeSelector === WATCHLISTS ? scrollTicks : mapNameToList(activeSelector);
        // Checks if the activeTick is part of the currently active list.
        /* According to the code structure, this component only changes the activeTick, after it has changed the list
        *  and it is loaded if required. Hence, if the activeTick is changed, and it is not in the list,
        * it must have been triggered by the user from some other component, not part of ListsSelection.
        * The above is the simple intuition, which gets quite complicated because of edge conditions, especially since
        * Watchlists are loaded post selection by the user.
        *
        * */
        const clearListSelectionCondition =
            (!(listCurrentlySelected == null || listCurrentlySelected?.length === 0) && !listCurrentlySelected?.some(ticker => ticker.tickerId === activeTick.id));
        if (clearListSelectionCondition) {
            setActiveSelector(null);
            setActiveList(null);
            setIndex(-1);
            setListLength(0);
            setList([]);
            setLocalTick(null);
            return;
        }

        if (localTick?.id === activeTick?.id) {
            return;
        }

        setLocalTick(activeTick);

        let goToIndexInternal, goToNextInternal, goToPrevInternal;

        if (activeSelector === WATCHLISTS) {

            goToIndexInternal = (index) => {
                setActiveTick({
                    name: scrollTicks[index].studioTicker,
                    id: scrollTicks[index].tickerId
                });
            };

            goToNextInternal = () => {
                if (scrollTicks.length <= 0) {
                    messageApi.open({
                        type: 'warning',
                        content: 'No items in list!',
                    });
                }

                if (scrollTicks.length > 0) {
                    messageApi.open({
                        type: 'loading',
                        content: 'Opening Next in Line...',
                    });

                    let currentIndex = -1;

                    for (let i = 0; i < scrollTicks.length; i++) {
                        if (scrollTicks[i].tickerId === activeTick.id) {
                            currentIndex = i;
                            break;
                        }
                    }


                    let nextIndex = (currentIndex + 1) % scrollTicks.length;

                    goToIndexInternal(nextIndex);
                }
            }

            goToPrevInternal = () => {
                if (scrollTicks.length <= 0) {
                    messageApi.open({
                        type: 'warning',
                        content: 'No items in list!',
                    });
                }

                if (scrollTicks.length > 0) {
                    messageApi.open({
                        type: 'loading',
                        content: 'Opening Previous in Line...',
                    });

                    let currentIndex = -1;
                    for (let i = 0; i < scrollTicks.length; i++) {
                        if (scrollTicks[i].tickerId === activeTick.id) {
                            currentIndex = i;
                            break;
                        }
                    }

                    let nextIndex = (scrollTicks.length + currentIndex - 1) % scrollTicks.length;

                    goToIndexInternal(nextIndex);
                }
            }

        } else {
            goToIndexInternal = (index) => {
                const nextPosition = listCurrentlySelected[index];
                setActiveTick({
                    name: nextPosition.studioTicker,
                    id: nextPosition.tickerId
                });

            }

            goToNextInternal = () => {
                const listToUse = listCurrentlySelected;
                const currentIndex = _.findIndex(listToUse, position => position.tickerId === activeTick.id);
                const nextIndex = (currentIndex + 1) % listToUse?.length;
                goToIndexInternal(nextIndex)
            }
            goToPrevInternal = () => {
                const listToUse = listCurrentlySelected;
                const currentIndex = _.findIndex(listToUse, position => position.tickerId === activeTick.id);
                const prevIndex = (currentIndex - 1 + listToUse?.length) % listToUse?.length;

                goToIndexInternal(prevIndex);
            }
        }

        setList(listCurrentlySelected.map(item => item.studioTicker));
        setGoToNext(() => goToNextInternal);
        setGoToPrev(() => goToPrevInternal);
        setGoToIndex(() => goToIndexInternal);
        setIndex(getIndex(listCurrentlySelected, activeTick));

        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.key === '.') {
                goToNextInternal();
            }
            if (e.ctrlKey && e.key === ',') {
                goToPrevInternal();
            }
        }

        window.removeEventListener('keydown', keyDownRef.current);

        keyDownRef.current = handleKeyPress;

        window.addEventListener('keydown', handleKeyPress);

    }, [activeTick]);

    useEffect(() => {
        return () => {
            window.removeEventListener('keydown', keyDownRef.current);
        }
    }, []);

    return (
        <ListsDataContext.Provider value={{
            watchLists,
            contextHolder,
            loadingWatchList,
            activeList,
            setActiveList,
            messageApi,
            showMiniView,
            setShowMiniView,
            goToNext,
            goToPrev,
            activeSelector,
            setActiveSelector,
            goToIndex,
            index,
            listLength,
            list,
        }}>
            {children}
        </ListsDataContext.Provider>
    )

};