import {createContext, useContext, useEffect, useRef, useState} from "react";
import {ViewContextStore} from "../views/ViewContext";
import {RefreshContext} from "../../commonContexts/RefreshContextProvider";
import {convertActiveProjectionsToMapOfProjectionsWithKey} from "./defaultProjectionsUtility";
import {getProjectionsChartData} from "../../../../api/data/DataProvider";
import {addIdioSeriesIfChartHasIdio} from "../DashboardReportContextProvider";
import {ProjectionsInfoContext} from "./ProjectionsInfoProvider";

/*
* activeProjections: {'<seriesName>': [{queryType: chartName, algorithm: 'DEFAULT ALGO'}]};
*
*
* */
//Todo: Communicate that availbleProjections have not been computed
export const ProjectionsDataStore = createContext(null);

const generateEmptyApiStateObject = () => ({
    chartData: null,
    isError: false,
    loading: false,
});

const generateDataStateForProjections = (activeProjections) => {
    const dataState = {};
    const projectionsMap = convertActiveProjectionsToMapOfProjectionsWithKey(activeProjections);
    Object.keys(projectionsMap).forEach(projectionKey => {
        dataState[projectionKey] = generateEmptyApiStateObject();
    })
    return dataState;
}

//Todo: does not factor in projections with api calls, but no longer in active projections:
const getProjectionsWithoutApiCalls = (activeProjections, dataState) => {

    const activeProjectionsKeysMap = convertActiveProjectionsToMapOfProjectionsWithKey(activeProjections);
    // API calls missing:
    const missingApiCalls = {};
    Object.keys(activeProjectionsKeysMap).forEach(projectionKey => {
        if(dataState[projectionKey] != null ) {
            if( (dataState[projectionKey].isError || dataState[projectionKey].chartData == null) && !dataState[projectionKey].loading  ) {
                missingApiCalls[projectionKey] = activeProjectionsKeysMap[projectionKey];
            }
        } else {
            missingApiCalls[projectionKey] = activeProjectionsKeysMap[projectionKey];
        }
    });

    return missingApiCalls;
}

function makeRequiredApiCalls(activeProjections, dataState, setDataState, tick, brand, abortControllerRef, reversePopulateChartConfig) {
    const uncalledProjections = getProjectionsWithoutApiCalls(activeProjections, dataState);
    Object.keys(uncalledProjections).forEach(projectionKey => {
        const projectionDetails = uncalledProjections[projectionKey];
        setDataState(prev => ({
            ...prev,
            [projectionKey]: {
                chartData: null,
                isError: false,
                loading: true
            }
        }));
        getProjectionsChartData(tick.id, brand.id, projectionDetails.queryType, projectionDetails.algorithm, projectionDetails.seriesName, abortControllerRef.current)
            .then(apiResult => {
                addIdioSeriesIfChartHasIdio(apiResult);
                setDataState(prev => ({
                    ...prev,
                    [projectionKey]: {
                        chartData: apiResult,
                        isError: false,
                        loading: false
                    }
                }));
                // Reverse populate the chart config:
                const newSeriesConfigObject = {};
                apiResult.data?.series.forEach(seriesItem => {
                    newSeriesConfigObject[seriesItem.name] = {
                        visible: seriesItem.visible,
                        yAxisVisible: false
                    }
                });

                if(reversePopulateChartConfig != null) reversePopulateChartConfig(projectionKey, newSeriesConfigObject, apiResult.data?.seriesMetadata);

            }).catch(err => {
            console.error(err);
            setDataState(prev => ({
                ...prev,
                [projectionKey]: {
                    chartData: null,
                    isError: true,
                    loading: false
                }
            }))
        });

    });
}

export const ProjectionsDataProvider = ({tick, brand, children}) => {

    //Todo: Should use the common priority mechanism from overall api context?

    const { reversePopulateChartConfig } = useContext(ViewContextStore);

    const { activeProjections, availableProjectionsUpdated } = useContext(ProjectionsInfoContext);

    const {refresh} = useContext(RefreshContext);

    const [dataState, setDataState] = useState({});

    const abortControllerRef = useRef(null);

    useEffect(() => {
        if(abortControllerRef.current && abortControllerRef.current.abort != null) {
            abortControllerRef.current.abort();
        }

        abortControllerRef.current = new AbortController();
        setDataState({}); //Clear out the data state so that API calls can be computed again.
        if(!availableProjectionsUpdated) return;
        // Send an empty data state below, since setDataState above will not reflect at this point:
        makeRequiredApiCalls(activeProjections, {}, setDataState, tick, brand, abortControllerRef, reversePopulateChartConfig);
    }, [tick, brand, refresh]);

    useEffect(() => {
        //Do a diff and find the projections which need an api to be triggered and trigger it:
        makeRequiredApiCalls(activeProjections, dataState, setDataState, tick, brand, abortControllerRef, reversePopulateChartConfig);
    }, [activeProjections, availableProjectionsUpdated])

    return (
        <ProjectionsDataStore.Provider value={{dataState}}>
            {children}
        </ProjectionsDataStore.Provider>
    )
}