import {useContext} from "react";
import {ViewContextStore} from "./DashboardReport/views/ViewContext";
import {useDataLatencyHook} from "./commonHooks/DataLatencyHook";
import {ExtraWeekIndicator} from "./components/ExtraWeekIndicator";
import {Layout, Menu, Space, Switch, Tooltip} from "antd";
import {Header} from "antd/es/layout/layout";
import {TickerDropdown} from "./components/TickerDropdown";
import {BrandDropdown} from "./components/BrandDropdown";
import {DashboardQueryTypes} from "../../api/data/DashboardQueryTypes";
import {
    MdCurrencyFranc,
    MdOpenInNew,
    MdOutlineViewCarousel,
    MdRefresh,
    MdStackedLineChart,
    MdSupervisorAccount
} from "react-icons/md";
import {Link} from "react-router-dom";
import {AiOutlineFundView} from "react-icons/ai";

import {
    DASHBOARDS_PATH,
    FACTORS_PATH,
    FINDATA_PATH,
    INTRADAY_PATH,
    LINKEDIN_PATH,
    MACRO_PATH,
    NAV_PATH,
    POSITIONS_PATH
} from "./PathConstants";
import {ShortcutsDropDown} from "./components/ShortcutsDropDown";
import {BsCalendar4Week} from "react-icons/bs";
import {RiAlignBottom} from "react-icons/ri";
import {RefreshContext} from "./commonContexts/RefreshContextProvider";
import {DASHBOARD_GRID} from "./DashboardReport/DashboardReport";
import {addUcToUrlAndOpenInNewTab} from "./DashboardReport/DashboardReportViewComponent";

const NavBar = (props) => {

    const {
        isDailyStackAlignment,
        setDailyStackAlignment,
        showWeeklySeries,
        setShowWeeklySeries,
        showPeerIndices,
        setShowPeerIndices,
        shortcutTogglesAndState,

        combineAxesBulkToggle,
        toggleCombineAxisBulk,
    } = useContext(ViewContextStore) ?? {
        isDailyStackAlignment: false,
        setDailyStackAlignment: () => {
        },
        showWeeklySeries: false,
        setShowWeeklySeries: () => {
        },
        showPeerIndices: false,
        setShowPeerIndices: () => {
        }
    };

    const {refresh, toggleRefresh} = useContext(RefreshContext);

    const {getLatencySuffix} = useDataLatencyHook(props.activeTick, props.activeBrand, refresh);

    const scrollToElement = (id) => props.scrollToItem(id);

    return (
        <Layout>
            <Header style={{height: 45, position: "fixed", padding: '0 10px', width: "100%", zIndex: 1000}}>
                <ExtraWeekIndicator activeTick={props.activeTick}/>
                <Menu selectable={false} mode={"horizontal"} theme={"dark"}
                      style={{height: 45, alignItems: "center", overflow: "hidden"}}
                >
                    <Menu.Item key="navbar-0"
                               onClick={() => scrollToElement(DASHBOARD_GRID)}>Grid<sub>q</sub></Menu.Item>
                    <Menu.Item key="navbar-14"
                               onClick={() => scrollToElement(DashboardQueryTypes.IDIO)}>Idio</Menu.Item>
                    <Menu.Item key="navbar-15"
                               onClick={() => props.toggleCorrelReportVisibility()}>Correls<sub>w</sub></Menu.Item>
                    <Menu.Item key="navbar-1"
                               onClick={() => scrollToElement(DashboardQueryTypes.QTR_YOY_LAG0)}>1Y <sub>e</sub></Menu.Item>
                    <Menu.Item key="navbar-11"
                               onClick={() => scrollToElement(DashboardQueryTypes.PROFIT_YOY_LAG0)}>GM <sub>r</sub></Menu.Item>
                    <Menu.Item key="navbar-2"
                               onClick={() => scrollToElement(DashboardQueryTypes.QTR_YOY2_LAG0)}>2Y <sub>t</sub></Menu.Item>
                    <Menu.Item key="navbar-3"
                               onClick={() => scrollToElement(DashboardQueryTypes.QTR_YOY3_LAG0)}>3Y <sub>y</sub></Menu.Item>
                    <Menu.Item key="navbar-4"
                               onClick={() => scrollToElement(DashboardQueryTypes.QTR_LEVELS_LAG0)}>LvL <sub>u</sub></Menu.Item>
                    <Menu.Item key="navbar-5"
                               onClick={() => scrollToElement(DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK)}>GT{getLatencySuffix('GOOGLE_TRENDS')}
                        <sub>i</sub></Menu.Item>
                    <Menu.Item key="navbar-6"
                               onClick={() => scrollToElement(DashboardQueryTypes.WEEKLY_DYN_SW_STACK)}>SW{getLatencySuffix('SIMILAR_WEB')}
                        <sub>o</sub></Menu.Item>
                    <Menu.Item key="navbar-9"
                               onClick={() => scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES)}>OR
                        D{getLatencySuffix('EARNEST_ORION_DEBIT')}
                        <sub>p</sub></Menu.Item>
                    <Menu.Item key="navbar-19"
                               onClick={() => scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES)}>OR
                        C{getLatencySuffix('EARNEST_ORION_CREDIT')}
                        <sub>p</sub></Menu.Item>
                    <Menu.Item key="navbar-10"
                               onClick={() => scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES)}>Velorum{getLatencySuffix('EARNEST_VELA_VELORUM')}
                        <sub>[</sub></Menu.Item>
                    <Menu.Item key="navbar-12"
                               onClick={() => scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES)}>Gamma{getLatencySuffix('EARNEST_VELA_GAMMA')}
                        <sub>]</sub></Menu.Item>
                    <Menu.Item key="navbar-8"
                               onClick={() => scrollToElement(DashboardQueryTypes.WEEKLY_STACK_SM_SALES)}>SM{getLatencySuffix('BBG_SECONDMEASURE')}
                        <sub>\</sub></Menu.Item>
                    <Menu.Item key="navbar-16"
                               onClick={() => scrollToElement(DashboardQueryTypes.DAILY_STACK_CC_BASELINE)}>CC <sub>s</sub></Menu.Item>
                    <Menu.Item key="navbar-17"
                               onClick={() => scrollToElement(DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US)}>APP{getLatencySuffix('APPTOPIA_APP_DATA')}
                        <sub>a</sub></Menu.Item>
                    <Menu.Item key="navbar-18" >
                        <span onClick={() => props.toggleProjectionsVisibility()}
                              style={{marginLeft: '10px', cursor: 'pointer'}}>
                            UC<sub>1</sub>
                        </span>
                        <span onClick={() => addUcToUrlAndOpenInNewTab()}
                              style={{marginLeft: '10px', cursor: 'pointer'}}>
                            <MdOpenInNew/>
                        </span>
                    </Menu.Item>
                    <Menu.Item key="navbar-20"
                               onClick={() => {}}>Y{getLatencySuffix('YIPIT')}
                    </Menu.Item>
                    <Menu.Item disabled>
                        <div style={{display: "flex"}}>

                            <TickerDropdown activeTick={props.activeTick} setActiveTick={props.setActiveTick}
                                            setShowMacroView={props.setShowMacroView}
                                            tickerOptions={props.tickerOptions}
                                            setActiveBrand={props.setActiveBrand}
                            />
                            <BrandDropdown activeTick={props.activeTick} activeBrand={props.activeBrand}
                                           setActiveBrand={props.setActiveBrand} popout={true}/>
                        </div>
                    </Menu.Item>
                    <Menu.Item
                        style={{background: 'transparent'}}
                        onClick={toggleRefresh}
                    >
                        <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip title="Click to refresh all data.">
                                <MdRefresh style={{color: 'white', fontSize: 25}}

                                />
                            </Tooltip>
                        </Space>
                    </Menu.Item>
                    <Menu.Item>
                        <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip title="Hover to access more shortcuts.">
                                <ShortcutsDropDown
                                    toggleTriggersAndState={shortcutTogglesAndState}
                                    iconClassName={'navbar-action-icon'}
                                    rootIconClassName={'navbar-shortcuts-root-icon'}
                                />
                            </Tooltip>
                        </Space>
                    </Menu.Item>
                    <Menu.Item style={{background: 'transparent'}}
                               onClick={() => setShowPeerIndices(prev => !prev)}
                    >
                        <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip title="Use Ctrl+I to Toggle Peer Indices">
                                <MdSupervisorAccount
                                    style={showPeerIndices ? {fontSize: 25, color: "#50C878"} : {
                                        fontSize: 25,
                                        color: "#ffffff"
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    </Menu.Item>
                    <Menu.Item
                        style={{background: 'transparent'}}
                        onClick={() =>
                            toggleCombineAxisBulk()
                        }
                    >
                        <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip
                                title="Press Ctrl+O to toggle axis combine. Enables Idio, Si, and puts the remaining series on a common axis. Appied across charts, but Levels.">
                                <MdStackedLineChart
                                    style={combineAxesBulkToggle ? {fontSize: 25, color: "#50C878"} : {
                                        fontSize: 25,
                                        color: "#ffffff"
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    </Menu.Item>
                    <Menu.Item
                        style={{background: 'transparent'}}
                        onClick={() => setShowWeeklySeries(prev => !prev)}
                    >
                        <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip
                                title="Press to toggle weekly series. Turning on disables App, Placer and SW, and enables all weeklies which have parent active. Note, turning off does not enable App, Placer and SW.">
                                <BsCalendar4Week
                                    style={showWeeklySeries ? {fontSize: 25, color: "#50C878"} : {
                                        fontSize: 25,
                                        color: "#ffffff"
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    </Menu.Item>
                    <Menu.Item
                        style={{background: 'transparent'}}
                        onClick={() => setDailyStackAlignment((prev) => !prev)}
                    >
                        <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip title="Press Ctrl+D to toggle daily series alignment">
                                <RiAlignBottom
                                    style={isDailyStackAlignment ? {fontSize: 25, color: "#50C878"} : {
                                        fontSize: 25,
                                        color: "#ffffff"
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    </Menu.Item>
                    {props.showMacroView && <Menu.Item disabled>
                        <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip title="Press Ctrl+M to toggle">
                                <Switch
                                    checked={props.showMacroView}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    onChange={(checkValue) => props.setShowMacroView(checkValue)}
                                /> Macro</Tooltip>
                        </Space>
                    </Menu.Item>}
                    <Menu.SubMenu
                        key={'MINIVIEW-SUBMENU'}
                        title={<MdOutlineViewCarousel
                            onClick={props.toggleMiniView}
                            style={props.showMiniView ? {fontSize: 25, color: "#50C878"} : {
                                fontSize: 25,
                                color: "#ffffff"
                            }}
                        />}
                    >
                        <Menu.Item onClick={props.toggleMiniViewMode}>
                            <span>{props.miniViewMode3x3 ? '2x2' : '3x3'}</span>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key={'NAVIGATION_SUBMENU'}
                        title={
                            <Link to={NAV_PATH} target={"_blank"} style={{textDecoration: "none"}}><AiOutlineFundView
                                style={{fontSize: 25, color: "#ffffff"}}/></Link>
                        }
                    >
                        <Menu.Item>
                            <Link to={NAV_PATH} target={"_blank"} style={{textDecoration: "none"}}>NAV
                                Screen</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={POSITIONS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Positions</Link>
                        </Menu.Item>

                        <Menu.Item>
                            <Link to={DASHBOARDS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Dashboard</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={FACTORS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Factors</Link>
                        </Menu.Item>

                        <Menu.Item>
                            <Link to={INTRADAY_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Intraday</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={MACRO_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Macro</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={LINKEDIN_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>LinkedIn</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item
                        style={{background: 'transparent'}}
                    >
                        <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip title="Go to findata for ticker">
                                <Link to={FINDATA_PATH + "?tickerName=" + props.activeTick.name} target={"_blank"}
                                      style={{textDecoration: "none"}}><MdCurrencyFranc style={{fontSize: 25}}/></Link>
                            </Tooltip>
                        </Space>
                    </Menu.Item>
                </Menu>
            </Header>
        </Layout>
    )
}

export default NavBar;
