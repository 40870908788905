import {useContext} from "react";
import {ViewContextStore} from "../DashboardReport/views/ViewContext";
import {Dropdown, Space, Tooltip} from "antd";
import {ShortcutsDropDown} from "../components/ShortcutsDropDown";
import {MdRefresh, MdStackedLineChart, MdSupervisorAccount} from "react-icons/md";
import {BsCalendar4Week} from "react-icons/bs";
import {RiAlignBottom} from "react-icons/ri";
import {AiOutlineFundView} from "react-icons/ai";
import {Link} from "react-router-dom";
import {DASHBOARDS_PATH, FACTORS_PATH, INTRADAY_PATH, LINKEDIN_PATH, MACRO_PATH, NAV_PATH} from "../PathConstants";
import {RefreshContext} from "../commonContexts/RefreshContextProvider";

const NAVIGATION_ITEMS = [
    {
        key: '1',
        label: <Link to={'/'} target={"_blank"} style={{textDecoration: "none"}}>Oracle</Link>
    },
    {
        key: '2',
        label: <Link to={NAV_PATH} target={"_blank"} style={{textDecoration: "none"}}>NAV Screen</Link>
    },
    {
        key: '3',
        label: <Link to={DASHBOARDS_PATH} target={"_blank"} style={{textDecoration: "none"}}>Dashboard</Link>
    },
    {
        key: '4',
        label: <Link to={FACTORS_PATH} target={"_blank"} style={{textDecoration: "none"}}>Factors</Link>
    },
    {
        key: '5',
        label: <Link to={INTRADAY_PATH} target={"_blank"} style={{textDecoration: "none"}}>Intraday</Link>
    },
    {
        key: '6',
        label: <Link to={MACRO_PATH} target={"_blank"} style={{textDecoration: "none"}}>Macro</Link>
    },
    {
        key: '7',
        label: <Link to={LINKEDIN_PATH} target={"_blank"} style={{textDecoration: "none"}}>Linkedin</Link>
    }
]

export const ViewControlIcons = () => {

    const {
        isDailyStackAlignment,
        setDailyStackAlignment,
        showWeeklySeries,
        setShowWeeklySeries,
        showPeerIndices,
        setShowPeerIndices,
        shortcutTogglesAndState,

        combineAxesBulkToggle,
        toggleCombineAxisBulk,
    } = useContext(ViewContextStore) ?? {
        isDailyStackAlignment: false,
        setDailyStackAlignment: () => {
        },

        showWeeklySeries: false,
        setShowWeeklySeries: () => {
        },
        showPeerIndices: false,
        setShowPeerIndices: () => {
        }
    }

    const {refresh, toggleRefresh} = useContext(RefreshContext);

    return (
        <>
            <span className='action-icons-1'
                    onClick={toggleRefresh}
            >
                <Space direction="horizontal" style={{color: 'white'}}>
                            <Tooltip title="Click to refresh all data.">
                                <MdRefresh style={{color: 'white', fontSize: 25}}

                                />
                            </Tooltip>
                        </Space>
            </span>
        <span className='action-icons-all'>
                <Space direction="horizontal" style={{color: 'white'}}>
                        <Tooltip title="Hover to access more shortcuts.">
                            <ShortcutsDropDown
                                toggleTriggersAndState={shortcutTogglesAndState}
                                rootIconClassName={'action-icon-sizing'}
                                iconClassName={'shortcut-icon'}
                            />
                        </Tooltip>
                    </Space>
            </span>
        <span className={'action-icons-all'}
              onClick={() => setShowPeerIndices(prev => !prev)}
        >
                <Space direction="horizontal" style={{color: 'white'}}>
                    <Tooltip title="Use Ctrl+I to Toggle Peer Indices">
                        <MdSupervisorAccount
                            className={'action-icon-sizing'}
                            style={showPeerIndices ? {color: "#50C878"} : {
                                color: "#ffffff"
                            }}
                        />
                    </Tooltip>
                </Space>
            </span>
        <span
            className={'action-icons-all'}
            onClick={() => toggleCombineAxisBulk()
            }
        >
                <Space direction="horizontal" style={{color: 'white'}}>
                    <Tooltip title="Press Ctrl+A to toggle axis combine">
                        <MdStackedLineChart
                            className={'action-icon-sizing'}
                            style={combineAxesBulkToggle ? {color: "#50C878"} : {
                                color: "#ffffff"
                            }}
                        />
                    </Tooltip>
                </Space>
            </span>
        <span
            className={'action-icons-all'}
            onClick={() => setShowWeeklySeries(prev => !prev)}
        >
                <Space direction="horizontal" style={{color: 'white'}}>
                    <Tooltip title="Press to toggle weekly series. Turning on disables App, Placer and SW, and enables all weeklies which have parent active. Note, turning off does not enable App, Placer and SW.">
                        <BsCalendar4Week
                            className={'action-icon-sizing'}
                            style={showWeeklySeries ? {color: "#50C878"} : {
                                color: "#ffffff"
                            }}
                        />
                    </Tooltip>
                </Space>
            </span>
        <span
            className={'action-icons-all'}
            onClick={() => setDailyStackAlignment((prev) => !prev)}
        >
                <Space direction="horizontal" style={{color: 'white'}}>
                    <Tooltip title="Press Ctrl+D to toggle daily series alignment">
                        <RiAlignBottom
                            className={'action-icon-sizing'}
                            style={isDailyStackAlignment ? {color: "#50C878"} : {
                                color: "#ffffff"
                            }}
                        />
                    </Tooltip>
                </Space>
            </span>
        <Dropdown menu={{items: NAVIGATION_ITEMS}}>
            <Space direction="horizontal" style={{color: 'white'}}>
                <AiOutlineFundView className={'action-icon-sizing'} style={{color: "#ffffff"}}/>
            </Space>
        </Dropdown>
</>
)
}